import request from '@/api/index'

export function getUserInfomation ():any {
  return request({
    url: '/web/user/detail/',
    method: 'GET'
  })
}

// 获取受试者列表
export function getPatientList (params: object): any {
  return request({
    url: '/web/patient/list/',
    method: 'GET',
    params
  })
}
// 手机号解密
export function getPhone (params: object): any {
  return request({
    url: '/web/patient/phone/',
    method: 'GET',
    params
  })
}

// 获取受试者上报数据列表
export function getPatientQuestionData (params: object): any {
  return request({
    url: '/web/up-data/list/',
    method: 'GET',
    params
  })
}
// 获得上报数据详情
export function getPatientQuestionDataDetail (params: object): any {
  return request({
    url: '/web/up-data/detail/',
    method: 'GET',
    params
  })
}

// 获得上报数据详情
export function getPatientFollowupDetail (params: object): any {
  return request({
    url: '/web/patient/follow-up-detail/',
    method: 'GET',
    params
  })
}

// 获取基本信息
export function getBasicInfo (params: object): any {
  return request({
    url: '/web/patient/update-identifier/',
    method: 'GET',
    params
  })
}

// 修改基本信息
export function submitBasicInfo (data: object): any {
  return request({
    url: '/web/patient/update-identifier/',
    method: 'POST',
    data
  })
}

export function relinkVerify (data: object): any {
  return request({
    url: '/web/patient/relink-verify/',
    method: 'POST',
    data
  })
}
