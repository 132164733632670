





































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import Cookie from 'js-cookie'
import { getCenterList } from '@/api/modules/project'
import { getPatientQuestionData } from '@/api/modules/patient'
import { getSessionStorage } from '@/utils/storage'

@Component({
  components: {
    BreadCrumbs
  }
})
export default class extends Vue {
  private select: number =Number(Cookie.get('centerId')) || 0;
  private options: any = [];
  private patientNumber: string = '';
  private phoneNumber: string = '';
  private tableHeader: object = [
    { text: '序号', slot: 'index' },
    { text: '手机号', field: 'phone' },
    { text: '受试者编号', field: 'patientNumber' },
    { text: '中心名称', field: 'centerName' },
    { text: '问卷名称', field: 'questionnaireName' },
    { text: '关联访视', field: 'visitName' },
    { text: '提交时间', field: 'createTime', width: '200' },
    { text: '操作', slot: 'operation' }
  ];
  private tableData: object = [];
  private page: any = {
    currentPage: 1,
    pageSize: 10
  };
  private total: number = 0;
  private Qstatus: any = {
    0: '全部',
    1: '未激活',
    2: '进行中',
    3: '已完成',
    4: '已脱落'
  };
  private loading = true;

  get projectName (): number {
    return getSessionStorage('projectName')
  }
  created () {
    // 中心列表
    this.getCenter()
    // 获得上报数据
    this.getPatientData(1, 10)
  }
  @Watch('select', { deep: true })
  OnchangeSelect (newVal: any) {
    this.page.currentPage = 1
    this.page.pageSize = 10
    if (this.options.length === 0) {
      return
    }
    Cookie.set('centerId', Number(newVal))
    this.getPatientData(1, 10)
  }
  // 分页
  private changePage (value: number): void {
    this.page.currentPage = value
    this.getPatientData(value, this.page.pageSize)
  }
  private changeSize (value: number): void {
    this.page.pageSize = value
    this.getPatientData(this.page.currentPage, value)
  }
  // 获得中心列表
  private getCenter () {
    let params = {
      userId: Cookie.get('id'),
      projectId: Cookie.get('projectId')
    }
    getCenterList(params).then((res: any) => {
      if (res.data.list.length > 0) {
        this.options = [{ id: 0, name: '全部', code: '' }, ...res.data.list]
      }
      this.select = Number(Cookie.get('centerId')) || 0
    })
  }
  // 获得上报数据
  private getPatientData (currentPage:number, pageSize:number) {
    let data = {
      userId: Cookie.get('id'),
      projectId: Cookie.get('projectId'),
      centerId: this.select,
      keyword: this.patientNumber,
      phone: this.phoneNumber,
      currentPage: this.page.currentPage,
      pageSize: this.page.pageSize
    }
    getPatientQuestionData(data).then((res: any) => {
      this.tableData = res.data.list.map((item: any) => {
        return {
          ...item,
          createTime: this.timestampToTime(item.createTime)
        }
      })
      this.total = res.data.total
    })
  }
  private see (row: any, column: any, index: number) {
    this.$router.push({
      path: '/admin/subject-data-detail',
      query: {
        patientId: row.patientId,
        questionnaireId: row.questionnaireId,
        patientNumber: row.patientNumber,
        centerName: row.centerName,
        visitName: row.visitName,
        createTime: row.createTime
      }
    })
  }
  private search () {
    this.page.currentPage = 1
    this.page.pageSize = 10
    this.getPatientData(this.page.currentPage, this.page.pageSize)
    this.getPatientData(this.page.currentPage, this.page.pageSize)
  }
  private clean () {
    this.patientNumber = ''
    this.page.currentPage = 1
    this.page.pageSize = 10
    this.getPatientData(1, 10)
  }
  private timestampToTime (timestamp: any) {
    var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '年'
    var M =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '月'
    var D =
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日 '
    var h =
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var m =
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':'
    var s =
      date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + h + m + s
  }
}
